<!--能源设置--能源换算系数---->
<template>
  
  <div id="energyEquivIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
     <div class="header-title">
      <!-- 能源换算系数 -->
      <span>{{$t('energy.enedata464')}}</span>
    </div>

    <div class="main" ref="main" v-if="usertype==1">
        <a-tabs v-model="activeKey" type="card">
          <!-- 站点能源换算系数 -->
          <a-tab-pane key="1" :tab="$t('energy.enedata465')">
            <div style="width:100%;height:100%;">
                <div class="header">
                    <div class="header-left">
                        <div class="header-condition">
                            <!-- <span>能源类型：</span> -->
                            <span> {{$t('energy.enedata060')}}：</span>
                            <a-select v-model="kind" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in eneKinds"
                                  :key="index"
                                  :value="itme.no"
                                >
                                  {{ itme.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition">
                            <!-- <span>年份：</span> -->
                            <span> {{$t('energy.enedata046')}}：</span>
                            <a-select v-model="year" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in syearList"
                                  :key="index"
                                  :value="itme.no"
                                >
                                  {{ itme.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition">
                            <!-- <span>区域</span> -->
                              <span>{{$t('energy.enedata451')}}：</span>
                              <a-select v-model="areaId" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in divisions"
                                  :key="index"
                                  :value="itme.id"
                                >
                                  {{ itme.name }}
                                </a-select-option>
                            </a-select>
                            <!-- <a-input v-model="unitName"  :placeholder="getPlaceholder(2,'energy.enedata165')"  style="width: 150px"/> -->
                        </div>
                    </div>
                    <div>
                        <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
                    </div>
                </div>
                <div class="t-main" ref="tmain">
                    <!-- :scroll="tscroll" -->
                    <a-table 
                    :row-selection="rowSelection"
                    :columns="columns" 
                    :data-source="data"
                    :scroll="tscroll"
                    :row-key="record => record.id"  
                    :pagination="pagination"  
                     bordered size="middle" >
                            <template slot="chaozuo" slot-scope="text, record ">
                                <div class="editable-row-operations">
                                    <a-button type="primary" @click="() => editRecord(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                                    <a-popconfirm :title="$t('energy.enedata459')" @confirm="() => deleteRecord(record)">
                                        <a-button type="danger">{{$t('energy.enedata169')}}</a-button>
                                    </a-popconfirm>
                                </div>
                            </template>
                    </a-table>
                </div>
                <div class="footer">
                    <div>
                        <a-button type="primary" style="width: 120px" @click="batchDeleta" :disabled="selectedRowKeys.length==0">
                          {{$t('energy.enedata169')}}
                        </a-button>
                    </div>
                    <a-button type="primary" style="width: 120px" @click="addSiteEquiv">
                        {{$t('energy.enedata191')}}
                    </a-button>
                </div>
            </div>
          </a-tab-pane>
          <!-- 区域能源换算系数 -->
          <a-tab-pane key="2" :tab="$t('energy.enedata466')">
              <div style="width:100%;height:100%;">
                <div class="header">
                    <div class="header-left">
                        <div class="header-condition">
                            <!-- <span>能源类型：</span> -->
                            <span> {{$t('energy.enedata060')}}：</span>
                            <a-select v-model="equivAreaKind" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in eneKinds"
                                  :key="index"
                                  :value="itme.no"
                                >
                                  {{ itme.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition">
                            <!-- <span>年份：</span> -->
                            <span> {{$t('energy.enedata046')}}：</span>
                            <a-select v-model="equivAreaYear" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in syearList"
                                  :key="index"
                                  :value="itme.no"
                                >
                                  {{ itme.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition">
                            <!-- <span>区域</span> -->
                              <span>{{$t('energy.enedata451')}}：</span>
                              <a-select v-model="equivAreaId" style="width: 150px;">
                              <a-select-option
                                  v-for="(itme, index) in divisions"
                                  :key="index"
                                  :value="itme.id"
                                >
                                  {{ itme.name }}
                                </a-select-option>
                            </a-select>
                            <!-- <a-input v-model="unitName"  :placeholder="getPlaceholder(2,'energy.enedata165')"  style="width: 150px"/> -->
                        </div>
                    </div>
                    <div>
                        <a-button type="primary" style="width: 120px"  @click="searchEquivArea()">{{$t('energy.enedata006')}} </a-button>
                    </div>
                </div>
                <div class="t-main" ref="tmain">
                    <!-- :scroll="tscroll" -->
                    <a-table 
                    :row-selection="rowSelectionEquivArea"
                    :columns="columnEquivAreas" 
                    :data-source="equivAreaData"
                    :scroll="tscroll"
                    :row-key="record => record.id"  
                    :pagination="paginationEquivArea"  
                    bordered size="middle" >
                            <template slot="chaozuo" slot-scope="text, record ">
                                <div class="editable-row-operations">
                                    <a-button type="primary" @click="() => editEquivArea(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                                    <a-popconfirm :title="$t('energy.enedata459')" @confirm="() => deleteEquivArea(record)">
                                        <a-button type="danger">{{$t('energy.enedata169')}}</a-button>
                                    </a-popconfirm>
                                </div>
                            </template>
                    </a-table>
                </div>
                <div class="footer">
                    <div>
                        <a-button type="primary" style="width: 120px" @click="batchDeletaEquivArea" :disabled="selectedEquivAreaRowKeys.length==0">
                          {{$t('energy.enedata169')}}
                        </a-button>
                    </div>
                    <a-button type="primary" style="width: 120px" @click="addEquivArea">
                        {{$t('energy.enedata191')}}
                    </a-button>
                </div>
            </div>
<!-- addEquivArea,batchDeletaEquivArea,deleteEquivArea,editEquivArea, rowSelectionEquivArea,columnEquivAreas,paginationEquivArea,equivAreaData-->
          </a-tab-pane>
          <!-- <a-tab-pane key="3" tab="Tab 3">Content of Tab Pane 3</a-tab-pane> -->
        </a-tabs>
        <ene-equiv-site-dialog  :title="equivSiteTitle" :id="equivSiteId" :yearList="yearList" :divisions="divisions" :energyKinds="eneKinds"></ene-equiv-site-dialog>
        <ene-equiv-area-dialog  :title=" equivAreaTitle" :id="equivAreaValId" :yearList="yearList" :divisions="divisions" :energyKinds="eneKinds"></ene-equiv-area-dialog>
    </div>
    <div class="main" ref="main" v-else>
        <div style="width:100%;height:100%;padding-top: 5px;">
            <div class="header">
                <div class="header-left">
                    <div class="header-condition">
                        <!-- <span>能源类型：</span> -->
                        <span> {{$t('energy.enedata060')}}：</span>
                        <a-select v-model="kind" style="width: 150px;">
                          <a-select-option
                              v-for="(itme, index) in eneKinds"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="header-condition">
                        <!-- <span>年份：</span> -->
                        <span> {{$t('energy.enedata046')}}：</span>
                        <a-select v-model="year" style="width: 150px;">
                          <a-select-option
                              v-for="(itme, index) in syearList"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="header-condition">
                        <!-- <span>区域</span> -->
                          <span>{{$t('energy.enedata451')}}：</span>
                          <a-select v-model="areaId" style="width: 150px;">
                          <a-select-option
                              v-for="(itme, index) in divisions"
                              :key="index"
                              :value="itme.id"
                            >
                              {{ itme.name }}
                            </a-select-option>
                        </a-select>
                        <!-- <a-input v-model="unitName"  :placeholder="getPlaceholder(2,'energy.enedata165')"  style="width: 150px"/> -->
                    </div>
                </div>
                <div>
                    <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
                </div>
            </div>
            <div class="t-main" ref="tmain">
                <!-- :scroll="tscroll" -->
                <a-table 
                :row-selection="rowSelection"
                :columns="columns" 
                :data-source="data"
                :scroll="tscroll"
                :row-key="record => record.id"  
                :pagination="pagination"  
                bordered size="middle" >
                        <template slot="chaozuo" slot-scope="text, record ">
                            <div class="editable-row-operations">
                                <a-button type="primary" @click="() => editRecord(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                                <a-popconfirm :title="$t('energy.enedata459')" @confirm="() => deleteRecord(record)">
                                    <a-button type="danger">{{$t('energy.enedata169')}}</a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                </a-table>
            </div>
            <div class="footer">
                <div>
                    <a-button type="primary" style="width: 120px" @click="batchDeleta" :disabled="selectedRowKeys.length==0">
                      {{$t('energy.enedata169')}}
                    </a-button>
                </div>
                <a-button type="primary" style="width: 120px" @click="addSiteEquiv">
                    {{$t('energy.enedata191')}}
                </a-button>
            </div>
        </div>
        <ene-equiv-site-dialog  :title="equivSiteTitle" :id="equivSiteId" :yearList="yearList" :divisions="divisions" :energyKinds="eneKinds"></ene-equiv-site-dialog>
    </div>
    <!-- :visible="equivSiteVisible" -->
    
  </div>
</template>



<script>
import { getSiteEquivList ,getAreaEquivList,deleteSiteEquivInfo,deleteAreaEquivInfo  } from "../../../api/energy";
import EnergyEquivSiteDialog from './energyEquivSiteDialog.vue';
import EnergyEquivAreaDialog from './energyEquivAreaDialog.vue';


export default {
  name: "energyEquivIndex",
  data() {
    
    return {
      loading: false,
      activeKey:'1',      
      yearList:[],
      syearList:[],
      eneKinds:[{no:"0",text:"　"}],
      divisions:[{id:0,name:"　"}],

      columns:[
          {title: 'NO'		 
          ,dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                index +
                1
              }`,
            width: 60,},
          {title: this.$t('energy.enedata451')		, dataIndex: 'areaName'		 		 ,width:150},//'区域'
          {title: this.$t('energy.enedata046')		    , dataIndex: 'year'		 		     ,width:100},//'年度'
          {title: this.$t('energy.enedata060')		 , dataIndex: 'eneKind'		  	,customRender: (text, record, index) =>
              `${
                this.getEneKindName(record.eneKind)
              }`	,width:200      },//'能源类型'
          {title: this.$t('energy.enedata453')		, dataIndex: 'co2EquivDayAdjustless'				 ,width:150},//'换算系数'
          {title: this.$t('energy.enedata455')		, dataIndex: 'co2Unit'		 		 },//,width:150 '换算单位'
          // {title: 'Gr3'		, dataIndex: 'gr3'		 		 ,width:150},
          // {title: this.$t('energy.enedata002')		, dataIndex: 'kind'		 	 ,width:150},
          // {title: this.$t('energy.enedata165')		, dataIndex: 'unitName'				,width:150},
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],

      pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = 1;
            this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
          },
      },
      selectedRowKeys:[],
      tscroll:{},//x:700,y:240
      data:[],
      
      kind:"0",
      year:undefined,
      areaId:0,
      equivSiteId:0,
      equivSiteTitle:this.$t('energy.enedata462'),//'站点能源换算系数设置',
      equivSiteVisible:false,

      equivAreaId:0,
      equivAreaKind:"0",
      equivAreaYear:undefined,
      equivAreaValId:0,
      equivAreaTitle:this.$t('energy.enedata463'),//'站点能源换算系数设置',
      equivAreaVisible:false,
      columnEquivAreas:[
          {title: 'NO'		 
          ,dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.paginationEquivArea.defaultCurrent - 1) * this.paginationEquivArea.pageSize +
                index +
                1
              }`,
            width: 60,},
          {title: this.$t('energy.enedata451')		, dataIndex: 'areaName'		 		 ,width:150},//'区域'
          {title: this.$t('energy.enedata046')		    , dataIndex: 'year'		 		     ,width:100},//'年度'
          {title: this.$t('energy.enedata060')		 , dataIndex: 'equivKind'		  	,customRender: (text, record, index) =>
              `${
                this.getEneKindName(record.equivKind)
              }`	,width:200      },//'能源类型'
          {title: this.$t('energy.enedata453')		, dataIndex: 'equivDay'				 ,width:150},//'换算系数'
          {title: this.$t('energy.enedata455')		, dataIndex: 'unit'		 		 },//,width:150 '换算单位'
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],
      paginationEquivArea:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.paginationEquivArea.defaultCurrent = 1;
            this.paginationEquivArea.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.paginationEquivArea.defaultCurrent = current;
            this.paginationEquivArea.pageSize = size;
          },
      },
      equivAreaData:[],
      selectedEquivAreaRows:[],
      selectedEquivAreaRowKeys:[],
      usertype:0,
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.onSelectChange2,
        // onSelectAll: this.onSelectChange3,
        hideDefaultSelections: true,
        type:'checkbox',
      };
    },
    rowSelectionEquivArea() {
      const { selectedEquivAreaRows } = this;
      return {
        selectedEquivAreaRows,
        onChange: this.onEquivAreaSelectChange,
        onSelect: this.onEquivAreaSelectChange2,
        hideDefaultSelections: true,
        type:'checkbox',
      };
    },
  },
  activated() {
    this.loadSiteEquivList(0);
  },
  mounted() { 
    console.log(sessionStorage.getItem('user'));
    this.usertype = JSON.parse(sessionStorage.getItem('user')).userType;
    console.log('USER>>',this.usertype);
  },
  beforeDestroy() {
    
  },
  methods:{
    getEneKindName(eneKind){
      console.log(eneKind);
      let kind = eneKind+'';
      let name = kind;
      this.eneKinds.forEach(element => {
        if(element.no===kind){
          name = element.text;
        }
      });
      return name;
    },
    getPlaceholder(type,code){
      let str = '';
      let msg = this.$t('energy.enedata307');
      if(type===1){
        str = msg.replace("{text}",code);
      }else{
        str = msg.replace("{text}",this.$t(code));
      }
      return str;
    },
    // 表格高度计算
    tableSize() {
        
        
    },

    editRecord(record){
       this.equivSiteId = record.id;
       this.equivSiteVisible = true;
    },
    deleteRecord(record){
      let idList = [];
      idList.push(record.id);
      this.deleteSiteEquiv(idList);
    },
    batchDeleta(){
       let len = this.selectedRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata460"));//'请先选择需要删除的虚拟点');
            return ;
        }
        let idList = this.selectedRowKeys;
        this.$confirm({
            title: this.$t('energy.enedata461'),
            centered: true,
            onOk: () => {
                this.deleteSiteEquiv(idList);
            }
        });
    },
    deleteSiteEquiv(idList){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            idList:idList,

        };
       this.loading = true;
       try{
            console.log("DeleteSiteEquivInfo Params",params);
            deleteSiteEquivInfo(params)
            .then((res) => {
                console.log("DeleteSiteEquivInfo Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '05'){
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                this.search();
                this.loading = false;
            })
            .catch((err) => {
                console.log('DeleteSiteEquivInfo',err);
                this.loading = false;
            });
       }catch(e){
            this.loading = false;
       }
    },
    addSiteEquiv(){
       this.equivSiteId = 0;
       this.equivSiteVisible = true;
       console.log("AddSiteEquiv Params",this.equivSiteVisible);
    },
    loadSiteEquivList(action){
      
      let params =  {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            kind:this.kind,
            year:this.year?this.year:0,
            areaId:this.areaId,
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getSiteEquivList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
              if(params.action===0){
                  // this.year     = res.data.year+'';
                  this.yearList = res.data.yearList;
                  this.syearList = res.data.syearList;
                  this.eneKinds = res.data.eneKinds;
                  this.divisions = res.data.divisions;
              }else{

                this.data = res.data.equivSiteValues;
                this.tableSize();
              }
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    search(){
        this.selectedRowKeys = new Array();
        this.selectedRows = new Array();
        this.loadSiteEquivList(1);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed1: ", selectedRowKeys);
       console.log("selectedRows changed2: ", selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onSelectChange2(record, selected, selectedRows, nativeEvent) {
      console.log("onSelectChange2>>: ",selected, selectedRows);
      this.selectedRows    = selectedRows;
      this.selectedRowKeys = [];
      this.selectedRows.forEach(element => {
          this.selectedRowKeys.push(element.id);
      });
    },
    onSelectChange3(selected, selectedRows, changeRows) {
      // console.log("onSelectChange3>>: ",selected, selectedRows,changeRows);
      // this.selectedRows    = changeRows;
      // this.selectedRowKeys = selectedRows;
    },

    searchEquivArea(){
        this.selectedEquivAreaRowKeys = new Array();
        this.selectedEquivAreaRows = new Array();
        this.loadEquivAreaList(1);
    },
    loadEquivAreaList(action){
      
      let params =  {
            action:action,
            kind:this.equivAreaKind,
            year:this.equivAreaYear?this.equivAreaYear:0,
            areaId:this.equivAreaId,
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getAreaEquivList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
              if(params.action===0){
                  // this.year     = res.data.year+'';
                  this.yearList = res.data.yearList;
                  this.syearList = res.data.syearList;
                  this.eneKinds = res.data.eneKinds;
                  this.divisions = res.data.divisions;
              }else{
                this.equivAreaData = res.data.equivAreaValues;
                this.tableSize();
              }
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    addEquivArea(){
      this.equivAreaValId = 0;
      this.equivAreaVisible = true;
      console.log("AddSiteEquiv Params",this.equivAreaVisible);
    },
    batchDeletaEquivArea(){
       let len = this.selectedEquivAreaRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata460"));
            return ;
        }
        let idList = this.selectedEquivAreaRowKeys;
        this.$confirm({
            title: this.$t('energy.enedata461'),
            centered: true,
            onOk: () => {
                this.deleteEquivAreaInfo(idList);
            }
        });
    },
    deleteEquivArea(record){
       let idList = [];
       idList.push(record.id);
       this.deleteEquivAreaInfo(idList);
    },
    deleteEquivAreaInfo(idList){
       let params =  {
            action:0,
            idList:idList,
        };
       this.loading = true;
       try{
            console.log("DeleteEquivAreaInfo Params",params);
            deleteAreaEquivInfo(params)
            .then((res) => {
                console.log("DeleteEquivAreaInfo Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '05'){
                    this.$message.success(res.msg);
                    this.searchEquivArea(1);
                    this.selectedEquivAreaRowKeys = new Array();
                    this.selectedEquivAreaRows = new Array();
        
                } else {
                    this.$message.error(res.msg);
                }
                
                this.loading = false;
            })
            .catch((err) => {
                console.log('DeleteEquivAreaInfo',err);
                this.loading = false;
            });
       }catch(e){
            this.loading = false;
       }
    },
    editEquivArea(record){
        this.equivAreaValId = record.id;
        this.equivAreaVisible = true;
    },
    onEquivAreaSelectChange(selectedRowKeys, selectedRows) {
      console.log("EquivAreaSelectChange>>1: ", selectedRowKeys);
       console.log("EquivAreaSelectChange>>2: ", selectedRows);
      this.selectedEquivAreaRowKeys = selectedRowKeys;
      this.selectedEquivAreaRows    = selectedRows;
    },
    onEquivAreaSelectChange2(record, selected, selectedRows, nativeEvent) {
      console.log("EquivAreaSelectChange2>>: ",selected, selectedRows);
      this.selectedEquivAreaRows    = selectedRows;
      //  this.selectedEquivAreaRows.
      this.selectedEquivAreaRowKeys = [];
      this.selectedEquivAreaRows.forEach(element => {
          this.selectedEquivAreaRowKeys.push(element.id);
      });
      // this.selectedEquivAreaRowKeys = selectedRowKeys;
    },
    onEquivAreaSelectChange3(selected, selectedRows, changeRows) {
      // console.log("EquivAreaSelectChange3>>: ",selected, selectedRows,changeRows);
      // this.selectedEquivAreaRows    = changeRows;
      // this.selectedEquivAreaRowKeys = selectedRows;
    },
    //
  },
  components: {
    'ene-equiv-site-dialog':EnergyEquivSiteDialog,
    'ene-equiv-area-dialog':EnergyEquivAreaDialog,
    // 'ene-point-gr':EnePointGR,
  },
};
</script>

<style scoped>

#energyEquivIndex {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    height: calc(100% - 40px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.t-main{
    width: 100%;
    /* height:100%; */
    padding: 10px;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>


