<template>
  <div id="energyEquivAreaDialog" ref="energyEquivAreaDialog">
    <a-modal v-model="visible" :title="title"  :width="600" :getContainer="() => this.$refs.energyEquivAreaDialog" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
        <a-form-model ref="ruleForm" 
        :model="form" 
        :rules="rules"
        :label-col="labelCol" 
        :wrapper-col="wrapperCol"  >
            <!-- 能源类型 -->
            <a-form-model-item :label="$t('energy.enedata060')" ref="kind" prop="kind">
                <a-select v-model="form.kind" :placeholder="$t('energy.enedata237')" @change="handleChange">
                    <a-select-option
                        v-for="(itme, index) in energyKinds"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!-- 年度 -->
            <a-form-model-item :label="$t('energy.enedata046')" ref="year" prop="year">
                <a-select v-model="form.year" :placeholder="$t('energy.enedata450')" @change="handleChange" >
                    <a-select-option
                        v-for="(itme, index) in yearList"
                        :key="index"
                        :value="itme"
                        >
                        {{ itme }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!-- 地区 -->
            <a-form-model-item :label="$t('energy.enedata451')" ref="areaId" prop="areaId">
                <a-select v-model="form.areaId" :placeholder="$t('energy.enedata452')" @change="handleChange">
                    <a-select-option
                        v-for="(itme, index) in divisions"
                        :key="index"
                        :value="itme.id"
                    >
                        {{ itme.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!-- 换算系数 -->
            <a-form-model-item :label="$t('energy.enedata453')" ref="equiv" prop="equiv">
                <a-input-number style="width:100%;"
                    v-model="form.equiv"
                    @blur="
                    () => {
                        $refs.equiv.onFieldBlur();
                    }
                    "
                    :placeholder="$t('energy.enedata454')"
                />
            </a-form-model-item>
            <!-- 计量单位 -->
            <a-form-model-item :label="$t('energy.enedata455')" ref="unit" prop="unit">
                 <span slot="label">
                                {{$t('energy.enedata455')}}&nbsp;
                    <a-tooltip :title='disallowed'>
                    <a-icon type="question-circle-o" />
                    </a-tooltip>
                </span>
                <a-input 
                    v-model="form.unit"
                    @blur="
                    () => {
                        $refs.unit.onFieldBlur();
                    }
                    "
                    :placeholder="$t('energy.enedata456')"
                />
            </a-form-model-item>
            
            <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm" v-if="id==0">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
            
        </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
import {saveAreaEquivInfo, getAreaEquivInfo} from "../../../api/energy";
export default {
  name: 'EnergyEquivSiteDialog',
  props: {
    title: {
      type: String,
      default: '站点能源换算系数设置',
    },
    id: {
      type: Number,
      default:0,
    },
    energyKinds: {
      type: Array,
      default:[],
    },
    divisions: {
      type: Array,
      default:[],
    },
    yearList: {
      type: Array,
      default:[],
    },
    // visible: {
    //   type: Boolean,
    //   default:false,
    // },

  },
  data() {
      let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",;']/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
        callback();
      }
    };
    return {
        cloading:false,        
        visible:false,
        
        form:{id:0,equiv:'',year:'',kind:'',unit:'',areaId:''},
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        },
        
        rules: {
            equiv: [
                { required: true, message: this.$t('energy.enedata454'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            unit: [
                { required: true, message: this.$t('energy.enedata456'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata262'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            kind: [{ required: true, message: this.$t('energy.enedata237'), trigger: 'change' }],
            year: [{ required: true, message: this.$t('energy.enedata450'), trigger: 'change' }],
            areaId: [{ required: true, message: this.$t('energy.enedata452'), trigger: 'change' }],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.equivAreaVisible":{
      handler(val){
          console.log('watch equivAreaVisible>>>',val)
          if(val){              
              this.visible = this.$parent.equivAreaVisible;
              this.resetForm();
              if(this.id>0){
                  this.loadInfo();
              }else{
                  this.form = {id:0,equiv:'',year:'',kind:'',unit:'',areaId:''};
              }
              console.log('watch equivAreaVisible form>>>',this.form)
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          console.log('watch visible>>>',val)
          if(!val){
              this.$parent.equivAreaVisible =  val;
              console.log('watch visible  this.$parent.equivAreaVisible>>>', this.$parent.equivAreaVisible);
          }
      },
      deep:true,
      immediate:true,
    },
    
  },
  methods: {
        handleChange(e){
            
        },
        loadInfo(){
            this.cloading = false;
            getAreaEquivInfo(this.id)
            .then((res) => {
                console.log("GetAreaEquivInfo Result",res.data);
                if(res.errorCode === '00'){
                    if(res.data){
                        this.form.id = res.data.id;
                        this.form.equiv = res.data.equivDay;
                        this.form.unit = res.data.unit;
                        this.form.year = res.data.year;
                        this.form.kind = res.data.equivKind+"";
                        this.form.areaId = res.data.areaId;
                    }
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('GetAreaEquivInfo',err);
            });
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: this.$t('energy.enedata458'),//'确认要保存所设置的虚拟点信息吗？',
                        centered: true,
                        onOk: () => {
                            this.savaEquivValue();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.ruleForm){
                this.$refs.ruleForm.resetFields();
            }
        },
        savaEquivValue(){

            let year = parseInt(this.form.year);
            let kind = parseInt(this.form.kind);
            let areaId = parseInt(this.form.areaId);
            
            let equiv = {
                areaId: areaId,
                equivDay: this.form.equiv,
                equivDayNight: this.form.equiv,
                equivDayTime: this.form.equiv,
                equivKind: kind,
                equivNormal: this.form.equiv,
                equivPeak: this.form.equiv,
                equivTip: this.form.equiv,
                equivValley:this.form.equiv,
                unit:this.form.unit,
                id: this.form.id,
                year: year,
            };
              
            let equivAreaValues = [];
            equivAreaValues.push(equiv);
            let param = {
                action:1,
                equivAreaValues:equivAreaValues,
            };
            this.cloading = true;
            console.log("SavaEquivValue param",param);
            saveAreaEquivInfo(param)
            .then((res) => {
                console.log("SaveSiteEquivInfo Result",res.data);
                if(res.errorCode === '00'|| res.errorCode === '03'){
                   this.$message.success(res.msg);
                  
                   this.visible = false;
                   this.$parent.searchEquivArea();
                } else {
                    this.$message.error(res.msg);
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('saveEquivValueData',err);
            });
        },
  },
};
</script>
<style scoped>
    #energyEquivAreaDialog{
        height: 100%;
        width:100%;
        position: relative;
    }
</style>